@import '../../styles/base.scss';

.root__div {
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  overflow-y: auto;
  height: 100%;
}

.table__header {
  display: flex;
  background-color: $color-dark-bg;
  color: $color-white;
}

.table__header span {
  flex: 3;
  padding: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table__header span:nth-child(4) {
  flex: 2;
}

.table__header span:nth-child(5) {
  flex: 4;
}

.drawer__div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.drawer__child {
  padding: 10px;
}

.detail__group {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 5px;
  border: 1px solid $color-light-bg;
}

.detail__group__nested {
  flex: 1;
  margin: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid $color-light-bg;
}

.details__horizontal {
  display: flex;
}

.detail__header {
  font-size: $font-size-md;
}

.detail__value {
  font-size: $font-size-lg;
}

.error__div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: red;
}

.redemption__header {
  display: flex;
  justify-content: center;
  font-size: $font-size-lg;
}

.redemption_error {
  margin-top: 20px;
}

.referenceCode_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.search_area {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-sizing: border-box;
}

.download_error_message {
  color: red;
}

.download_button {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  p {
    padding: 10px 0;
  }
}
