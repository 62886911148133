.root__div {
  height: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 30px;
  flex: 1;
  overflow-y: scroll;
}

.spinner {
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
}

.search_area {
  margin-bottom: 1%;
}

.drawer_table {
  padding: 2%;
}

.drawer_search_area {
  margin-bottom: 2%;
}

.drawer_table_content {
  height: 85vh;
}

.drawer_table_content_page {
  height: 75vh;
}

.drawer_page_header {
  font-size: large;
  font-weight: bold;
}

.successMessage {
  align-self: center;
  justify-content: center;
  margin-bottom: 1%;
}

.table_search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2%;
}

.error_msg {
  color: red;
}

.success_msg {
  color: green;
}
