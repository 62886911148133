@import '../../styles/base.scss';

.date_picker__div {
  display: flex;
  align-items: center;
}

.date_picker__input {
  height: 34px;
  outline-offset: 0px;
  outline: none;
  font-size: 0.8rem;
  padding: 0 15px;
}
